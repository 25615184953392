<template>
  <section id="main">
    <div class="bg-main-block">
      <h1 class="pb-3">
        The next gen<br><span>Blockchain game</span>
      </h1>
      <h3 class="mt-3">
        The next gen Blockchain game
      </h3>

      <a href="https://t.me/gramilla_worldBot" class="btn btn-main mt-5">
        Start playing

        <svg class="arr arr-left" xmlns="http://www.w3.org/2000/svg" width="16" height="64" viewBox="0 0 16 64" fill="none">
          <path d="M16 0V64C13.594 64 11.4403 62.5075 10.5955 60.2547L1.05337 34.809C0.374215 32.9979 0.374215 31.0021 1.05337 29.191L10.5955 3.74532C11.4403 1.49248 13.594 0 16 0Z" fill="white"/>
        </svg>
        <svg class="arr arr-right" width="16" height="64" viewBox="0 0 16 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0V64C2.40603 64 4.55968 62.5075 5.40449 60.2547L14.9466 34.809C15.6258 32.9979 15.6258 31.0021 14.9466 29.191L5.40449 3.74532C4.55968 1.49248 2.40603 0 0 0Z" fill="white"/>
        </svg>
      </a>
    </div>

    <img class="img-game mt-4" src="@/assets/img/game.svg" alt="game">
  </section>
</template>

<script>
export default {
  name: 'MainBlock'
};
</script>

<style lang="scss" scoped>
#main {
  text-align: center;
  padding-top: 230px;
  padding-bottom: 130px;

  background: url('@/assets/img/bd-main-block.svg') bottom no-repeat;
  background-size: cover;

  .img-game {
    max-width: 100%;
    padding: 0 30px;
  }
}

h1 {
  font-size: 8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  span {
    color: #574CFF;
  }
}

h3 {
  color: #1E2329;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
}

.btn {
  display: inline-flex;
  position: relative;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #FFF;

  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.333;
  border-radius: 0;

  border: none;
  background-color: #574CFF;

  .arr {
    display: block;
    width: 16px;
    height: 100%;

    path {
      fill: #574CFF;
      transition: fill 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &.arr-left {
      position: absolute;
      top: 0;
      left: -15px;
    }
    &.arr-right {
      position: absolute;
      top: 0;
      right: -15px;
    }
  }

  &:hover {
    background: #904CFF;
    .arr {
      path {
        fill: #904CFF;
      }
    }
  }
}

@media (max-width: 1199px) {
  #main {
    padding-top: 160px;
    padding-bottom: 90px;
  }
  h1 {
    font-size: 6rem;
  }
}
@media (max-width: 767px) {
  #main {
    padding-top: 130px;
    padding-bottom: 30px;
  }
  h1 {
    font-size: 5rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}
@media (max-width: 520px) {
  #main {
    padding-top: 110px;
    padding-bottom: 0px;
  }
  h1 {
    font-size: 3.5rem;
  }
}
</style>
