<template>
  <section id="faq">
    <div class="container">
      <h4>
        Frequently asked questions
      </h4>

      <b-accordion id="accordion-1" flush class="mt-5">
        <b-accordion-item v-for="(faq, index) in faqs" :key="index" :id="'item-' + index" :visible="index === 0">
          <template #title>
            <span>{{ faq.question }}</span>
          </template>
          <p>{{ faq.answer }}</p>
        </b-accordion-item>
      </b-accordion>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FAQ',
  data() {
    return {
      faqs: [
        {
          question: 'What to do with NFT',
          answer: 'Launch gramilla bot to start playing'
        },
        {
          question: 'Where I can buy NFT?',
          answer: 'getgems.io - website where you can buy and sell gramilla NFT'
        },
        {
          question: 'How often are GRAM payouts',
          answer: 'Each hour by UTC timezone'
        },
        {
          question: 'How I can withdraw GRAM from my account?',
          answer: 'Press on the balance and than enter amount to withdraw.'
        },
        {
          question: 'What is premium bananas for?',
          answer: `You can use them like regular bananas, but you don't have any limits for it.`
        },
        {
          question: 'What to do if my new hex is not visible in game?',
          answer: 'Try to re-login in the game via settings'
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#faq {
  padding-top: 0px;
  padding-bottom: 98px;
  background: url("@/assets/img/bg-ask.svg") center no-repeat;
  background-size: cover;
  min-height: 900px;

  h4 {
    color: #1E2329;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.333;
  }
}

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: all 0.15s ease, color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: none;
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-btn-color: #fff;
  --bs-accordion-btn-bg: #fff;
  --bs-accordion-btn-icon: url(@/assets/img/closed.svg);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(@/assets/img/open.svg);;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-color: wite;
  --bs-accordion-active-bg: wite;
}
.accordion {
 max-width: 1196px;
  margin: 0 auto;
}

.accordion-item {
  clip-path: polygon(1% 0, 99% 0, 100% 15%, 100% 85%, 99% 100%, 1% 100%, 0 85%, 0 15%);
  border: none;
  margin-bottom: 12px;

  .accordion-button {
    font-family: "DINPro", sans-serif !important;
    color: #1E2329 !important;
    font-size: 1.125rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 1.777 !important;
    padding: 24px;
    margin-bottom: 12px;
    background: none!important;
    border: none!important;
    box-shadow: none!important;
  }
  .accordion-button:not(.collapsed) {
    padding: 24px 24px 12px 24px;
    background: none;
    border: none;
    box-shadow: none;
    margin: 0;
  }
  .accordion-button:focus {
    background: none;
    border: none;
    box-shadow: none;
  }
  .accordion-body {
    padding: 0 24px 24px 24px;
    background: none;
    border: none;
    box-shadow: none;
  }
  .accordion-body:focus {
    background: none;
    border: none;
    box-shadow: none;
  }

  p {
    border-top: 1px solid #EAECEF;
    color: #58667E;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 12px;
    margin-bottom: 0;
  }
}

@media (max-width: 1199px) {
  #faq {
    padding-bottom: 70px;
    background: url(http://localhost:8081/img/bg-ask.bebfc1f2.svg) bottom no-repeat;
    background-size: 130%;
    min-height: 0px;
  }
}

@media (max-width: 767px) {
  #faq {
    padding-bottom: 30px;
    background: url(http://localhost:8081/img/bg-ask.bebfc1f2.svg) bottom no-repeat;
    background-size: 150%;
    min-height: 0px;

    h4 {
      font-size: 2.5rem;
    }
  }
}
</style>
