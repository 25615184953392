import { createApp } from 'vue';
import {createBootstrap} from 'bootstrap-vue-next'

// Add the necessary CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import App from './App.vue';
import { registerScrollSpy } from 'vue3-scroll-spy'

import '@/assets/styles.scss';

const app = createApp(App);
registerScrollSpy(app)
app.use(createBootstrap({components: true, directives: true})) // Change this line
app.mount('#app');
