<template>
  <div id="app">
    <Menu />

    <div class="content-main-block" v-scroll-spy="{offset: 130}">
      <MainBlock />
      <AboutUs />
      <FAQ />
      <Roadmap />
      <Footer />
    </div>
  </div>
</template>

<script>
import Menu from './components/Menu.vue';
import MainBlock from './components/MainBlock.vue';
import AboutUs from './components/AboutUs.vue';
import Roadmap from './components/Roadmap.vue';
import FAQ from './components/FAQ.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Menu,
    MainBlock,
    AboutUs,
    Roadmap,
    FAQ,
    Footer,
  }
};
</script>
