<template>
  <nav class="navbar-custom fixed-top">
    <div class="container">
    <ul
    class="navbar-menu"
    v-scroll-spy
    v-scroll-spy-active="{selector: 'li.menu-item', class: 'active'}"
    >
  <li class="menu-item">
    <a v-scroll-to="'#about'" href="#about">About Us</a>
  </li>
  <li class="menu-item">
    <a v-scroll-to="'#gameflow'" href="#join-telegram">Gameflow</a>
  </li>
  <li>
    <a href="https://wiki.gramilla.world/" target="_blank">WIKI</a>
  </li>
  <li class="menu-item">
    <a v-scroll-to="'#roadmap'" href="#roadmap">Roadmap</a>
  </li>
</ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      isMenuActive: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/fonts.scss";

/* Custom styles */
.navbar-custom {
  z-index: 1000;
  width: 100%;
  position: fixed;
  top: 0;
}

.navbar-custom .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-brand {
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
}

.navbar-toggler {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
}

.navbar-menu {
  position: relative;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background: #FFF;
  margin-top: 15px;
  margin-bottom: 0;
  box-shadow: 8px 16px 32px 16px rgba(0, 0, 0, 0.08);
  &:before, &:after {
    content: '';
    display: block;
    width: 16px;
    height: 100%;
  }
  &:before {
    position: absolute;
    top: 0;
    left: -16px;
    background: url('@/assets/img/left-menu-arr.svg') center no-repeat;
    background-size: cover;
  }
  &:after {
    position: absolute;
    top: 0;
    right: -16px;
    background: url('@/assets/img/right-menu-arr.svg') center no-repeat;
    background-size: cover;
  }
}

.navbar-menu li {
  list-style: none;
}

.navbar-menu a {
  color: #1E2329;
  text-align: center;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;

  &:active, &:hover {
    color: #574CFF
  }
}

.navbar-menu .active a {
  color: #574CFF
}

.is-active {
  display: block;
}

@media (max-width: 520px) {
  .navbar-menu {
    padding: 16px 20px;
    gap: 20px;
    a {
      font-size: 1rem;
    }
  }
}
</style>
