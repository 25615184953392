<template>
  <section id="footer">
    <p class="copy">
      2024 © Gramilla.world | All rights reserved.Gramilla LLC
    </p>
  </section>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style lang="scss" scoped>
#footer {
  text-align: center;
  padding: 30px;

 .copy {
   color: #585E77;

   text-align: center;
   font-size: 0.875rem;
   font-style: normal;
   font-weight: 400;
   line-height: 1.7142;
 }
}
</style>
