<template>
  <section id="about">
    <div class="container">
      <h4>
        Your <span>Journey</span> Start Here
      </h4>

      <div class="row mt-5">
        <div class="col-12 col-md-4 mb-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-1"></div>
            </div>
            <p class="title">
              Connect Wallet
            </p>
            <p class="text">
              Connect your wallet to telegram bot of Gramilla World to start playing.
            </p>
          </div>
        </div>

        <div class="col-12 col-md-4 mb-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-2"></div>
            </div>
            <p class="title">
              Collect NFT
            </p>
            <p class="text">
              Get some NFTs to have more territory            </p>
          </div>
        </div>

        <div class="col-12 col-md-4 mb-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-3"></div>
            </div>
            <p class="title">
              Expand your territory
            </p>
            <p class="text">
              Attack your neighbours to get more territory and more GRAM 
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">

      <div class="join-box">
        <div class="img-box"></div>
        <p class="title">
          Join over 1000+ Gramillas on our Telegram channel
        </p>
        <p class="text">
          Join Gramillas on Telegram to stay updated with all the news and secrets of your favorite game! Our community is a place for communication and sharing experiences with like-minded people. Don't miss out!
        </p>

        <a href="https://t.me/GramillaWorld_ru" class="btn btn-main mt-5" target="_blank">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M22.1844 2.97184C21.9763 2.79552 21.7243 2.67887 21.4553 2.6343C21.1862 2.58973 20.9101 2.6189 20.6563 2.71871L3.35 9.51558C3.04646 9.63123 2.78934 9.84346 2.61824 10.1196C2.44715 10.3957 2.37157 10.7204 2.40313 11.0437C2.43129 11.3669 2.56346 11.6722 2.77981 11.9139C2.99616 12.1556 3.28504 12.3207 3.60313 12.3843L8 13.2562V18.75C7.99985 19.0472 8.08798 19.3377 8.25323 19.5847C8.41847 19.8318 8.65337 20.0242 8.92813 20.1375C9.10997 20.2106 9.304 20.2488 9.5 20.25C9.69701 20.2507 9.89218 20.212 10.0741 20.1364C10.256 20.0607 10.421 19.9495 10.5594 19.8093L12.9969 17.3812L16.6813 20.625C16.9533 20.8649 17.303 20.9981 17.6656 21C17.8251 21.0032 17.9839 20.9778 18.1344 20.925C18.3818 20.8467 18.6045 20.7052 18.7804 20.5144C18.9563 20.3237 19.0794 20.0904 19.1375 19.8375L22.6625 4.44371C22.7241 4.17809 22.7122 3.90069 22.6279 3.64135C22.5437 3.38201 22.3903 3.15054 22.1844 2.97184ZM17.675 19.5L9.95 12.7031L21.0781 4.66871L17.675 19.5Z" fill="#574CFF"/>
          </svg>
          <span>Join Telegram Channel</span>
          <svg class="arr arr-left" xmlns="http://www.w3.org/2000/svg" width="16" height="64" viewBox="0 0 16 64" fill="none">
            <path d="M16 0V64C13.594 64 11.4403 62.5075 10.5955 60.2547L1.05337 34.809C0.374215 32.9979 0.374215 31.0021 1.05337 29.191L10.5955 3.74532C11.4403 1.49248 13.594 0 16 0Z" fill="white"/>
          </svg>
          <svg class="arr arr-right" width="16" height="64" viewBox="0 0 16 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0V64C2.40603 64 4.55968 62.5075 5.40449 60.2547L14.9466 34.809C15.6258 32.9979 15.6258 31.0021 14.9466 29.191L5.40449 3.74532C4.55968 1.49248 2.40603 0 0 0Z" fill="white"/>
          </svg>
        </a>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'AboutUs'
};
</script>

<style lang="scss">
#about {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 160px;

  h4 {
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.333;
    padding-bottom: 48px;
    margin: 0;

    span {
      color: #574CFF;
    }
  }
}

.box-item {
  background-color: #FFF;
  clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
  padding: 24px;
  height: 100%;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 64px;
    background: url("@/assets/img/bg-icon.svg") center no-repeat;
    background-size: contain;
    margin: 0 auto 24px auto;
  }
  .icon-inner {
    width: 32px;
    height: 32px;

    &.icon-1 {
      background: url("@/assets/img/icon-wallet.svg") center no-repeat;
      background-size: contain;
    }

    &.icon-2 {
      background: url("@/assets/img/icon-buy.svg") center no-repeat;
      background-size: contain;
    }

    &.icon-3 {
      background: url("@/assets/img/icon-flover.svg") center no-repeat;
      background-size: contain;
    }
  }

  .title {
    color: #1E2329;

    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.333;
    margin: 0;
  }
  .text {
    color: #585E77;

    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 12px;
    margin-bottom: 0;
  }
}


.join-box {
  background-image: url("@/assets/img/bg-join.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #574CFF;
  clip-path: polygon(3% 0, 97% 0, 100% 10%, 100% 90%, 97% 100%, 3% 100%, 0 90%, 0 10%);
  padding: 48px;
  margin-top: 102px;

  .img-box {
    width: 186px;
    height: 48px;
    margin: 0 auto;
    background: url("@/assets/img/avatars.svg") center no-repeat;
    background-size: contain;
  }
  .title {
    color: #FFF;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.333;
    margin-top: 12px;
    margin-bottom: 0;
  }
  .text {
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .btn {
    display: inline-flex;
    position: relative;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #574CFF;

    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.333;
    border-radius: 0;

    border: none;
    background-color: #fff;

    .arr {
      display: block;
      width: 16px;
      height: 100%;

      path {
        fill: #fff;
        transition: fill 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

      &.arr-left {
        position: absolute;
        top: 0;
        left: -15px;
      }
      &.arr-right {
        position: absolute;
        top: 0;
        right: -15px;
      }
    }

    &:hover {
      color: #904CFF;
      .icon {
        path {
          fill: #904CFF
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  #about {
    .box-item {
      clip-path: polygon(10% 0, 90% 0, 100% 10%, 100% 90%, 90% 100%, 10% 100%, 0 90%, 0 10%);
    }
    .join-box {
      background-image: url("@/assets/img/bg-join.svg");
      background-position: bottom;
      background-size: contain;
      background-color: #574CFF;
      background-repeat: no-repeat;
    }
  }
}
@media (max-width: 767px) {
  #about {
    padding-top: 60px;
    padding-bottom: 30px;

    h1 {
      font-size: 5rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    h4 {
      font-size: 2.5rem;
      padding-bottom: 0px;
    }
    .box-item {
      clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
    }
    .join-box {
      clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
      padding: 30px;
      margin-top: 30px;
      margin-bottom: 30px;
      .title {
        font-size: 2.5rem;
      }
    }
  }
}
</style>
