<template>
  <section id="roadmap">
    <div class="container">
      <h4>
        Roadmap
      </h4>

      <div class="row">
        <div class="col-6 col-lg-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-1"></div>
            </div>
            <p class="title">
              Implement clan MVP
            </p>
            <p class="text">
              Cooperate with your frieds to create clan and fortify each other            </p>
          </div>
        </div>
        <div class="col-6 col-lg-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-1"></div>
            </div>
            <p class="title">
              Restrictions and penalties for inactive players
            </p>
            <p class="text">
              inactive players will loose their territory.            </p>
          </div>
        </div>
        <div class="col-6 col-lg-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-1"></div>
            </div>
            <p class="title">
              Referral system
            </p>
            <p class="text">
              Invite your frieds and earn 5% of their earnings and get 100 free bananas each            </p>
          </div>
        </div>
        <div class="col-6 col-lg-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-1"></div>
            </div>
            <p class="title">
              Release game v.1.0
            </p>
            <p class="text">
              Start of the presale for beta-testers, release of the game with x3 map size            </p>
          </div>
        </div>
        <div class="col-6 col-lg-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-1"></div>
            </div>
            <p class="title">
              Launching the "World Event"
            </p>
            <p class="text">
              First ingame global event with additional GRAM prizes           </p>
          </div>
        </div>
        <div class="col-6 col-lg-4">
          <div class="box-item">
            <div class="icon">
              <div class="icon-inner icon-1"></div>
            </div>
            <p class="title">
              Adding a player friendship system
            </p>
            <p class="text">
              Possibility to create pacts with your neighbours            </p>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Roadmap'
};
</script>

<style lang="scss" scoped>
#roadmap {
  padding-top: 62px;
  padding-bottom: 60px;

  h4 {
    color: #1E2329;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.333;
    padding-bottom: 70px;
    margin: 0;
  }

  .box-item {
    background: none;
    clip-path: none;
    padding: 24px;
    margin-bottom: 10px;
  }

  .row {
    background: url("@/assets/img/bg-roadmap.svg") center 51px no-repeat;
    background-size: contain;
  }
}

@media (max-width: 1399px) {
  #roadmap {
    .box-item {
      margin-bottom: 0px;
      padding-bottom: 2px;
    }
  }
}

@media (max-width: 1199px) {
  #roadmap {
    padding-top: 50px;
    .row {
      background: none;
    }
    h4 {
      padding-bottom: 60px
    }
  }
}

@media (max-width: 767px) {
  #roadmap {
    padding-top: 30px;
    padding-bottom: 30px;

    h4 {
      font-size: 2.5rem;
      padding-bottom: 30px
    }
  }
}
</style>
